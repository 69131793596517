import React, { useMemo } from 'react';
import { useLocation, Navigate, RouteProps } from 'react-router-dom';
import { UserInfo } from 'services/users';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { isValidRedirectUrl, Routes } from 'fnRoutes';
import { useAccount, useCompany, useCurrentUser, useSpoof } from 'hooks/api';
import { CONSTANTS, Role } from 'constants/common';
import { NotFoundPage } from 'views/NotFoundPage';
import { useBusinessTypeRegister } from 'components/OnboardingV1/state';
import { useFeatureFlagIdentify } from 'hooks/useFeatureFlag';
import { removeAuthCode } from 'helpers/common';
import { AuthErrorPage } from 'views/AuthErrorPage';
import { CONFIG } from 'config';
import { useCheckStripePlan } from 'hooks/api/useStripe';
import { Loading } from '../components/common';
import {
  isRoleNotAllowed,
  progressTrackerRedirect,
  Redirection,
  firstExperienceRedirect,
  wrongHomeRedirect,
  taxOnlyCustomerRedirect,
} from './redirects';

interface WithCoordinatorOptions {
  role?: Role;
  isEnabled?: boolean;
}

const isLoadingData = (
  loadingUser: boolean,
  loadingAccount: boolean,
  loadingCompany: boolean,
  loadingPayment: boolean,
) => loadingUser || loadingAccount || loadingCompany || loadingPayment;

const isSpoofingCustomer = (
  loginUser: UserInfo | undefined,
  isAdminSpoof: boolean,
) => loginUser?.role === CONSTANTS.USER_ROLES.CUSTOMER && isAdminSpoof;

const isPathNameInvalid = (
  pathname: string,
  homeUrl: string,
  isEnabled: boolean,
) => pathname !== homeUrl && !isEnabled;

const withRedirection =
  (
    Component: React.ElementType,
    options: WithCoordinatorOptions = { role: 'any', isEnabled: true },
  ) =>
  (props: RouteProps) => {
    const { isAuthenticated } = useAuth0();
    const { isAdminSpoof } = useSpoof();
    const {
      currentUser,
      loginUser,
      isLoading: isLoadingUser,
      error: currentUserError,
    } = useCurrentUser({
      staleTime: Infinity,
    });
    const {
      account,
      isLoading: isLoadingAccount,
      error: accountError,
    } = useAccount(currentUser?.accountId, { staleTime: Infinity });
    const { stripePlan, isLoading: isLoadingPayment } = useCheckStripePlan(
      currentUser?.accountId,
    );
    const alreadyPaid = !!stripePlan;
    const { company, isLoading: isLoadingCompany } = useCompany(
      currentUser?.role === CONSTANTS.USER_ROLES.CUSTOMER
        ? currentUser?.companyId
        : undefined,
      { staleTime: Infinity },
    );
    const businessType = useBusinessTypeRegister((state) => state.businessType);
    const { pathname } = useLocation();
    const { role: allowedRole = 'any', isEnabled = true } = options;

    if (
      isLoadingData(
        isLoadingUser,
        isLoadingAccount,
        isLoadingCompany,
        isLoadingPayment,
      )
    ) {
      return <Loading />;
    }

    if (currentUser == null || account == null) {
      if (!isAuthenticated) {
        // if the user is not authenticated, we need to redirect to the auth page
        const previousUrl = encodeURIComponent(
          window.location.pathname + window.location.search,
        );
        if (isValidRedirectUrl(previousUrl)) {
          window.location.href = `${window.location.origin}/auth?redirectUri=${previousUrl}`;
        } else {
          window.location.href = `${window.location.origin}/auth`;
        }
      } else {
        // if the user is authenticated, we need to redirect to the post login page to let the user and account data be loaded
        return <Navigate to={Routes.POST_LOGIN} replace />;
      }
      // sometimes currentUser is not available at the moment, we could ask customer to refresh the page to have reload the data
      return <AuthErrorPage error={currentUserError ?? accountError} />;
    }
    const { role } = currentUser;
    const homeUrl =
      currentUser.role === CONSTANTS.USER_ROLES.CUSTOMER
        ? Routes.CUSTOMER_HOME
        : Routes.ADMIN_HOME;
    const isHome = [Routes.CUSTOMER_HOME, Routes.ADMIN_HOME].includes(pathname);

    if (isSpoofingCustomer(loginUser, isAdminSpoof)) {
      return <NotFoundPage />;
    }
    // verify path and redirect to proper url if needed
    if (isPathNameInvalid(pathname, homeUrl, isEnabled)) {
      return <NotFoundPage />;
    }
    if (!isHome && isRoleNotAllowed([allowedRole], role, pathname)) {
      return <NotFoundPage />;
    }

    const redirection = new Redirection(
      pathname,
      account,
      currentUser,
      company,
      businessType,
      isAdminSpoof,
      alreadyPaid,
    );

    const newPath = redirection
      .apply(wrongHomeRedirect)
      .apply(taxOnlyCustomerRedirect)
      .apply(firstExperienceRedirect)
      .apply(progressTrackerRedirect)
      .getPath();
    if (newPath !== pathname) {
      return <Navigate to={newPath} replace />;
    }
    return <Component {...props} />;
  };

type ProtectedRouteProps = {
  role?: Role;
  isEnabled?: boolean;
  component: React.ComponentType<any>;
};
export const ProtectedRoute = ({
  component = () => null,
  role,
  isEnabled,
  ...props
}: ProtectedRouteProps) => {
  const { isAuthenticated } = useAuth0();
  // Use memo to save the protected component as withAuthenticationRequired
  // will generate a new component whenever the url changes
  // It also affect the performance
  const previousUrl = encodeURIComponent(
    window.location.pathname + removeAuthCode(window.location.search),
  );
  const ComponentWithRedirection = withRedirection(component, {
    isEnabled,
    role,
  });
  // load feature flag identity to give user access to feature
  useFeatureFlagIdentify(); // I'm not sure if this is the perfect place to put this function

  const NewComponent = useMemo(
    () =>
      (window as any).Cypress
        ? ComponentWithRedirection
        : withAuthenticationRequired(ComponentWithRedirection, {
            onRedirecting: Loading,
            returnTo:
              !isAuthenticated && isValidRedirectUrl(previousUrl)
                ? previousUrl
                : undefined,
            loginOptions: {
              authorizationParams: {
                redirect_uri: `${window.location.origin}/postlogin`,
                audience: CONFIG.auth0Audience,
              },
            },
          }),
    [component, isAuthenticated],
  );
  return <NewComponent {...props} />;
};
