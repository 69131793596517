import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CONFIG } from 'config';
import { AuthService } from 'services';
import { Category, logError } from 'helpers/sentry';
import { isValidRedirectUrl, Routes } from 'fnRoutes';

const generateServiceInstance = (
  serviceOptions: AxiosRequestConfig & { redirectToLogin?: boolean },
) => {
  const { redirectToLogin = true, ...options } = serviceOptions;
  const instance = axios.create(options);

  instance.interceptors.request.use((req) => {
    const accessToken = AuthService.userToken();

    req.headers!.Authorization = `Bearer ${accessToken}`;
    return req;
  });

  /**
   * For 401 - Redirect to login page.
   */
  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const status = error.response?.status;

      // log error
      logError({
        category: Category.Service,
        message: error.response?.data || error.message,
        data: error.response,
      });

      if (status === 401 && redirectToLogin) {
        // as a workaround, we need to check the request url to determine if we should redirect to login page
        const allow401 = ['users/me'];
        const allow401Path = [
          Routes.POST_LOGIN,
          Routes.POST_LOGIN_1120S,
          Routes.POST_LOGIN_BIB,
        ];
        const { pathname } = window?.location || {};
        if (
          allow401.includes(error?.config?.url || '') &&
          allow401Path.includes(pathname)
        ) {
          return Promise.reject(error);
        }
        const previousUrl = encodeURIComponent(
          window.location.pathname + window.location.search,
        );
        if (isValidRedirectUrl(previousUrl)) {
          window.location.href = `${window.location.origin}/auth?redirectUri=${previousUrl}`;
        } else {
          window.location.href = `${window.location.origin}/auth`;
        }
      }

      return Promise.reject(error);
    },
  );
  return instance;
};

const apiClient = generateServiceInstance({
  baseURL: CONFIG.apiUrl,
  timeout: 10000,
});

export const payrollService = generateServiceInstance({
  baseURL: `${CONFIG.apiPayrollUrl}/v1/`,
  timeout: 30000,
  redirectToLogin: false,
});

/**
 * @deprecated use import { api } from 'axios'
 */
export default apiClient;

export const api = apiClient;
export { axios };
export type { AxiosError, AxiosResponse };
