import { useAuth0 } from '@auth0/auth0-react';
import { CONFIG } from 'config';
import { clearDataWhenLogOut } from 'services/auth';

function useLogout() {
  const { logout: logoutAuth0 } = useAuth0();

  const logout = () => {
    clearDataWhenLogOut();
    logoutAuth0({
      logoutParams: {
        returnTo: CONFIG.logoutUrl,
      },
    });
  };

  return {
    logout,
  };
}

export default useLogout;
