import moment, { isMoment, Moment } from 'moment';
import i18n from 'translations/i18n';

const DEFAULT_FORMAT = 'MMM D, H:mm';
export const MOMENT_DATE_FORMAT = 'MM/DD/YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const FULL_DATE_FORMAT = 'MMMM Do, YYYY';
export const DATE_FORMAT = 'MMMM D';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mma';
export const DATE_TIME_WITH_WEEKDAY = 'ddd MMM DD, YYYY';
export const DATE_TIME_WITHOUT_WEEKDAY = 'MMM DD, YYYY';

export const getPrettyDate = (
  data: Moment | string | Date,
  format = MOMENT_DATE_FORMAT,
): string => {
  const date = isMoment(data) ? data : moment(data);
  return date.format(format);
};

export const getPrettyDateTime = (
  data: Moment | string | Date,
  format = DEFAULT_FORMAT,
): string => {
  const datetime = isMoment(data) ? data : moment(data);
  // test
  if (datetime.diff(new Date(), 'days') > 0) {
    return datetime.fromNow();
  }
  return datetime.format(format);
};

// 1-59s   =>  now
// 1-59min =>  x minutes ago
// 1-24hr  =>  x hours ago
// 1-7days =>  x days ago
// > 7days =>  MM/DD/YYYY

export const getPrettyDateTimeFromNow = (
  data: string | Moment,
  now?: Moment,
): string => {
  const { t } = i18n;
  const dateTimeNow = now || moment();
  const datetime = isMoment(data) ? data : moment(data);
  // this should not happen, hence return '-'
  if (dateTimeNow.isBefore(datetime)) {
    return t('common.NA');
  }

  const secDiff = dateTimeNow.diff(datetime, 'seconds');
  if (secDiff < 60) {
    return t('dateTimeFormat.now');
  }

  const minuteDiff = dateTimeNow.diff(datetime, 'minutes');
  if (minuteDiff < 60) {
    return t('dateTimeFormat.minuteFromNow', { count: minuteDiff });
  }

  const hourDiff = dateTimeNow.diff(datetime, 'hours');
  if (hourDiff < 24) {
    return t('dateTimeFormat.hourFromNow', { count: hourDiff });
  }

  const dayDiff = dateTimeNow.diff(datetime, 'days');
  if (dayDiff <= 7) {
    return t('dateTimeFormat.dayFromNow', { count: dayDiff });
  }

  return datetime.format(MOMENT_DATE_FORMAT);
};

export const UIDateFormat = (date: string) => {
  try {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${mo}/${da}/${ye}`;
  } catch {
    return 'N/A';
  }
};

export const getISOFormatTime = (date: string): string => {
  try {
    const res = moment(date);
    return res.utc(true).startOf('day').toISOString();
  } catch {
    return '';
  }
};

export const formatDate = (
  date: string | Moment | undefined,
  defaultOutput: string = '',
): string => {
  try {
    if (date) {
      return moment(date).format(MOMENT_DATE_FORMAT);
    }
    return defaultOutput;
  } catch {
    return defaultOutput;
  }
};

export const getDateWithoutTime = (dateStr: string): Moment => {
  const utcDate = new Date(dateStr);
  const userTimezoneOffset = utcDate.getTimezoneOffset() * 60000;
  return moment(new Date(utcDate.getTime() + userTimezoneOffset));
};

export const UIDateTimeFormat = (date: string, format = DATE_TIME_FORMAT) =>
  moment(date).format(format);

export const apiDateFormatToDateOnly = (date: Date): string =>
  moment.utc(date).format(FULL_DATE_FORMAT);

export const convertLocalTimeToUTC = (date: string | Moment): Moment => {
  const localTime = moment(date);
  const month = localTime.month();
  const day = localTime.day();
  const utcTime = moment.utc(localTime).set({ month, day });
  return utcTime;
};

export const convertLocalTimeToUTCStartDay = (date: string | Moment): Moment =>
  convertLocalTimeToUTC(date).startOf('day');

// if current month is less than 6, then select current year
// else select previous year
// for example, if current month is July 2025, then select current year 2025
// if current month is June 2025, then select previous tax year 2024
export const shouldSelectCurrentTaxYear = (): boolean => {
  const currentMonth = moment().month();
  return currentMonth >= 6;
};

export const getYearList = (currentYear: number, length: number): string[] => {
  const yearList = [];
  for (let i = 0; i < length; i += 1) {
    yearList.push((currentYear - i).toString());
  }
  return yearList;
};
